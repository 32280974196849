import React from 'react';
import './Countdown.css';

class Countdown extends React.Component {
  constructor(props){
    super(props);  
    const target = new Date("12/01/2024").getTime();
    const now = new Date().getTime();
    let s = target - now;
    this.state = { time: {}, seconds: s };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.second = 1000;
    this.minute = this.second * 60;
    this.hour = this.minute * 60;
    this.day = this.hour * 24;
  };


  secondsToTime(secs){
    let days = Math.floor(secs / this.day);
    let hours = Math.floor((secs % (this.day)) / (this.hour));
    let minutes = Math.floor((secs % (this.hour)) / (this.minute));
    let seconds = Math.floor((secs % (this.minute)) / this.second);

    let obj = {
      "d": days,
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }  
  componentDidMount() {
    //console.log('Countdown | componentDidMount started');
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar,seconds: this.state.seconds});
    this.startTimer();
  }
  startTimer() {
    //console.log('Countdown | Timer started');
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  } 
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  countDown() {
    //console.log('Countdown | countDown++');
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1000;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    //Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(this.timer);
    }
  }  

  render(){
    if (this.state.seconds === 0) {
      return (<div></div>);
    } else {
    return(
        <div className="CountdownContainer">
          <div id="title">
          Līdz 2024.gada Adventei
          </div>
          <div className="countdown">
            <ul>
              <li><span id="days">{this.state.time.d}</span>dienas</li>
              <li><span id="hours">{this.state.time.h}</span>stundas</li>
              <li><span id="minutes">{this.state.time.m}</span>min</li>
              <li><span id="seconds">{this.state.time.s}</span>sek</li>
            </ul>
          </div>         
        </div>
    );
    }
    }
}

export default Countdown;